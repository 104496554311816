import React from 'react';
import { SumData, SumDataSparkline } from '../JetSum';
import { ColumnData, ColumnType } from '../JetTable';
import { JetOrderOption } from '../../helpers/Query';
import { BarOption } from '../JetChart';
import { ColorMapping } from '../../configs';
import { TIME_LABEL } from '../../helpers/Query/JetFilterQuery';
import { PageData } from './PageData';
import { ViewMode } from './ViewMode';
import { JetColumnId } from './JetColumnId';
import {
    modifyVideoUrl,
    titlePostProcessor,
    watchlistTogglePostProcessor,
    homeDepartmentPostProcessor,
    conversionProcessor,
    convertToConversionPercent,
    conversionSearchWithConversionPostProcessor,
    removeDecimalsProcessor,
    watchTimeFormat
} from './_postProcessors';
import { BRANDS } from '../../helpers/constants/brands';

const PAGE_LABELS = {
    topArticle: 'Top Artikel',
    home: 'Home',
    homeWeb: 'Home Web',
    homeDesktop: 'Home (Desktop)',
    homeMobile: 'Home (Mobil)',
    premium: 'Premium',
    premiumBild: 'Premium BILD',
    premiumSportBild: 'Premium SportBILD',
    video: 'Video',
    push: 'Push',
    seo: 'SEO',
    watchlist: 'Watchlist',
    positionAnalysis: 'Positionsanalyse'
};

const SUM_LABELS = {
    topArticle: 'Pageviews',
    home: 'Ref. Home',
    premium: 'Conversions',
    video: 'Gestartet/Media Started',
    seo: 'Ref. Search'
};

const SHARED_PAGE_CONFIG = {
    video: (brand) => new PageData({
        label: PAGE_LABELS.video,
        sums: [
            new SumData(JetColumnId.mediaStarted, 'Media Starts'),
            new SumData(null)
                .setSparkline({
                    timeFilter: [TIME_LABEL.today], sparklines: [
                        new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, { label: 'Gestern', chartOptions: { areaStyle: { opacity: 0.2 } } })
                            .setRequestOptions({ sparklineTime: 'yesterday' }),
                        new SumDataSparkline(JetColumnId.mediaStarted, { label: 'Media Starts' })
                    ]
                })
        ],
        columns: [
            new ColumnData(JetColumnId.mediaHeadline, 'Titel', ColumnType.LINK, false)
                .setRelatedColumns([
                    JetColumnId.isPremium,
                    JetColumnId.wasPremium,
                    JetColumnId.cmsIsPremium,
                    JetColumnId.documentId,
                    JetColumnId.mediaId
                ])
                .setPostProcessor(modifyVideoUrl(brand, titlePostProcessor({ videoIcon: false, showKicker: false }))),
            new ColumnData(JetColumnId.mediaStarted, 'Gestartet')
                .setHighlight(),
            new ColumnData(JetColumnId.mediaCompleted, 'Beendet'),
            new ColumnData(JetColumnId.mediaWatchtimeAvg, 'Watchtime', ColumnType.NUMERIC, true, JetOrderOption.DESC)
                .setPostProcessor(watchTimeFormat)
        ]
    }),
    seo: (brand) => {
        const sumDataSparklines = [
            new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, { label: 'Gestern', chartOptions: { stack: 'PIs Gestern', areaStyle: { opacity: 0.2 } } })
                .setRequestOptions({ sparklineTime: 'yesterday' }),
            new SumDataSparkline(JetColumnId.referrerSearchWithNews, { label: 'News', chartOptions: { stack: 'PIs' } }),
            new SumDataSparkline(JetColumnId.referrerSearchWithDiscovery, { label: 'Discover', chartOptions: { stack: 'PIs' } }),
            new SumDataSparkline(JetColumnId.referrerSearchNoNewsNoDiscovery, { label: 'Search', chartOptions: { stack: 'PIs' } })
        ];
        const columns = [
            new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                .setRelatedColumns([
                    JetColumnId.url,
                    JetColumnId.isPremium,
                    JetColumnId.wasPremium,
                    JetColumnId.cmsIsPremium,
                    JetColumnId.cmsType,
                    JetColumnId.cmsKicker,
                    JetColumnId.articleKicker,
                    JetColumnId.articleType
                ])
                .setPostProcessor(titlePostProcessor()),
            new ColumnData(JetColumnId.referrerSearch, 'SEO-Traffic')
                .setHighlight()
                .setSparkline({ style: { height: 80, width: 150 }, sparklineSharedMax: brand !== 'bild' }),
            new ColumnData(JetColumnId.referrerSearchWithNews, 'News')
                .setHighlight()
                .setSparkline({ style: { height: 80, width: 150 } }),
            new ColumnData(JetColumnId.referrerSearchWithDiscovery, 'Discover')
                .setHighlight()
                .setSparkline({ style: { height: 80, width: 150 } }),
            new ColumnData(JetColumnId.referrerSearchNoNewsNoDiscovery, 'Search')
                .setHighlight()
                .setSparkline({ style: { height: 80, width: 150 } }),
            new ColumnData(JetColumnId.referrerSearchRate, 'SEO-Anteil')
                .setPostProcessor(removeDecimalsProcessor),
            new ColumnData(JetColumnId.pisTotal, 'PIs (gesamt)'),
            ...['bild', 'welt'].includes(brand) ? [
                new ColumnData(JetColumnId.conversionSearch, 'SEO Conversions')
                    .setRelatedColumns([
                        JetColumnId.conversion,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium
                    ])
                    .setPercentChart({ postProcessor: convertToConversionPercent, hideIfNotPremium: true })
                    .setPostProcessor(conversionProcessor(conversionSearchWithConversionPostProcessor))
            ] : [],
            new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                .setPostProcessor(watchlistTogglePostProcessor(brand))
        ];
        return new PageData({
            label: PAGE_LABELS.seo,
            sums: [
                new SumData(JetColumnId.referrerSearch, SUM_LABELS.seo),
                new SumData(null)
                    .setSparkline({
                        timeFilter: [TIME_LABEL.today], sparklines: sumDataSparklines
                    })
            ],
            columns: columns
        });
    },
    watchlist: (brand) => new PageData({
        label: PAGE_LABELS.watchlist,
        viewModes: [ViewMode.WATCHLIST],
        watchlistKey: brand,
        filter: {
            topTeaserPositionFilter: true
        },
        columns: [
            new ColumnData(JetColumnId.id, 'ID')
                .setRelatedColumns([
                    JetColumnId.url,
                    JetColumnId.id,
                    JetColumnId.pisTotal,
                    JetColumnId.conversion,
                    JetColumnId.pisPremium,
                    JetColumnId.isPremium,
                    JetColumnId.wasPremium,
                    JetColumnId.cmsIsPremium,
                    JetColumnId.pisConversionPage,
                    JetColumnId.department,
                    JetColumnId.title,
                    JetColumnId.teaserPosition,
                    JetColumnId.cmsKicker,
                    JetColumnId.articleKicker,
                    JetColumnId.cmsTeaserImage,
                    JetColumnId.cmsPublicationDate,
                    JetColumnId.readThroughRate,
                    JetColumnId.articleType
                ]),
            new ColumnData(null, 'PIs nach Referrer')
                .setBarChart({
                    barOptions: [
                        new BarOption(JetColumnId.pisMobile, 'Mobil', ColorMapping.MOBIL),
                        new BarOption(JetColumnId.pisDesktop, 'Desktop', ColorMapping.DESKTOP),
                        new BarOption(JetColumnId.pisApps, 'App', ColorMapping.APP),
                        new BarOption(JetColumnId.referrerSearchNoNewsNoDiscovery, 'Search', ColorMapping.SEARCH),
                        new BarOption(JetColumnId.referrerSearchWithDiscovery, 'Discover'),
                        new BarOption(JetColumnId.referrerSearchWithNews, 'News'),
                        new BarOption(JetColumnId.referrerSocial, 'Social', ColorMapping.SOCIAL),
                        new BarOption(JetColumnId.referrerMisc, 'Misc', ColorMapping.MISC),
                        new BarOption(JetColumnId.pisNoReferrer, 'Kein Referrer', ColorMapping['KEIN REFERRER'])
                    ],
                    style: { width: '100%' }
                }),
            new ColumnData(null, 'Conversions nach Referrer')
                .setBarChart({
                    barOptions: [
                        new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                        new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                        new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                        new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                        new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                        new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                    ],
                    style: { width: '100%' }
                }),
            new ColumnData(null, 'PIs')
                .setSparkline({
                    style: {
                        'width': 'auto',
                        'height': 200
                    },
                    sumSparklines: [
                        new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, {
                            label: 'Gestern',
                            requestValue: true,
                            timeFilter: [TIME_LABEL.today],
                            chartOptions: { stack: 'PIs Gestern', areaStyle: { opacity: 0.2 } }
                        })
                            .setRequestOptions({ sparklineTime: 'yesterday' }),
                        new SumDataSparkline(JetColumnId.pisMobile, { label: 'Mobil', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.pisDesktop, { label: 'Desktop', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.pisApps, { label: 'App', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerSearchWithNews, { label: 'News', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerSearchWithDiscovery, { label: 'Discover', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerSearchNoNewsNoDiscovery, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.pisNoReferrer, { label: 'Kein Referrer', chartOptions: { stack: 'PIs' } })
                    ]
                }),
            new ColumnData(null, 'Conversions By Referrer and Time')
                .setSparkline({
                    sumSparklines: [
                        new SumDataSparkline(JetColumnId.conversionMobile, { label: 'Mobil' }),
                        new SumDataSparkline(JetColumnId.conversionDesktop, { label: 'Desktop' }),
                        new SumDataSparkline(JetColumnId.conversionApp, { label: 'App' }),
                        new SumDataSparkline(JetColumnId.conversionSearch, { label: 'Search' }),
                        new SumDataSparkline(JetColumnId.conversionSocial, { label: 'Social' }),
                        new SumDataSparkline(JetColumnId.conversionMisc, { label: 'Misc' })
                    ]
                })
        ]
    }),
    booksTopArticle: (brand) => new PageData({
        label: PAGE_LABELS.topArticle,
        sums: [
            new SumData(JetColumnId.pisTotal, SUM_LABELS.topArticle)
                .setBarChart({
                    label: 'PIs nach Referrer', style: { width: 250 }, barOptions: [
                        new BarOption(JetColumnId.pisWeb, 'Web'),
                        new BarOption(JetColumnId.pisApps, 'App'),
                        new BarOption(JetColumnId.referrerSearch, 'Search'),
                        new BarOption(JetColumnId.referrerSocial, 'Social'),
                        new BarOption(JetColumnId.referrerMisc, 'Misc'),
                        new BarOption(JetColumnId.pisNoReferrer, 'Kein Referrer')
                    ]
                }),
            new SumData(null)
                .setSparkline({
                    style: { height: 200 }, timeFilter: [TIME_LABEL.today], sparklines: [
                        new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, { label: 'Gestern', chartOptions: { stack: 'PIs Gestern', areaStyle: { opacity: 0.2 } } })
                            .setRequestOptions({ sparklineTime: 'yesterday' }),
                        new SumDataSparkline(JetColumnId.pisWeb, { label: 'Web', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.pisApps, { label: 'App', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.referrerMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } }),
                        new SumDataSparkline(JetColumnId.pisNoReferrer, { label: 'Kein Referrer', chartOptions: { stack: 'PIs' } })
                    ]
                })
        ],
        columns: [
            new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                .setRelatedColumns([
                    JetColumnId.url,
                    JetColumnId.isPremium,
                    JetColumnId.wasPremium,
                    JetColumnId.cmsIsPremium,
                    JetColumnId.cmsType,
                    JetColumnId.cmsKicker,
                    JetColumnId.articleKicker
                ])
                .setPostProcessor(titlePostProcessor()),
            new ColumnData(JetColumnId.pisTotal, 'PIs')
                .setHighlight()
                .setSparkline({ sparklineSharedMax: true, style: { width: 'min(max(20vw, 220px), 500px)' } }),
            new ColumnData(JetColumnId.pisWeb, 'Web'),
            new ColumnData(JetColumnId.pisApps, 'App'),
            new ColumnData(JetColumnId.referrerSearch, 'Search'),
            new ColumnData(JetColumnId.referrerSocial, 'Social'),
            new ColumnData(JetColumnId.referrerMisc, 'Misc'),
            new ColumnData(JetColumnId.pisNoReferrer, 'Kein Referrer'),
            new ColumnData(JetColumnId.readThroughRate, 'Read-Through').
                setRelatedColumns([
                    JetColumnId.isPremium,
                    JetColumnId.articleType
                ])
                .setRTR(),
            new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                .setPostProcessor(watchlistTogglePostProcessor(brand))
        ],
        viewModes: [ViewMode.TABLE, ViewMode.BAR_CHART]
    })
};

const PAGE_CONFIGS = {
    bild: {
        topArticle: new PageData({
            label: PAGE_LABELS.topArticle,
            sums: [
                new SumData(JetColumnId.pisTotal, SUM_LABELS.topArticle)
                    .setVisitPrediction({ hasVisitPrediction: true })
                    .setBarChart({
                        label: 'PIs nach Referrer', style: { width: 250 }, barOptions: [
                            new BarOption(JetColumnId.pisMobile, 'Mobil'),
                            new BarOption(JetColumnId.pisDesktop, 'Desktop'),
                            new BarOption(JetColumnId.pisApps, 'App'),
                            new BarOption(JetColumnId.referrerSearch, 'Search'),
                            new BarOption(JetColumnId.referrerSocial, 'Social'),
                            new BarOption(JetColumnId.referrerMisc, 'Misc'),
                            new BarOption(JetColumnId.pisNoReferrer, 'Kein Referrer')
                        ]
                    }),
                new SumData(null)
                    .setSparkline({
                        style: { height: 200 }, timeFilter: [TIME_LABEL.today], sparklines: [
                            new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, { label: 'Gestern', chartOptions: { stack: 'PIs Gestern', areaStyle: { opacity: 0.2 } } })
                                .setRequestOptions({ sparklineTime: 'yesterday' }),
                            new SumDataSparkline(JetColumnId.pisMobile, { label: 'Mobil', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisDesktop, { label: 'Desktop', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisApps, { label: 'App', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisNoReferrer, { label: 'Kein Referrer', chartOptions: { stack: 'PIs' } })
                        ]
                    })
            ],
            columns: [
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsType,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker,
                        JetColumnId.articleType
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.pisTotal, 'PIs')
                    .setHighlight()
                    .setSparkline({ style: { width: 'min(max(12vw, 170px), 500px)' } }),
                new ColumnData(JetColumnId.pisMobile, 'Mobil'),
                new ColumnData(JetColumnId.pisDesktop, 'Desktop'),
                new ColumnData(JetColumnId.pisApps, 'App'),
                new ColumnData(JetColumnId.referrerSearch, 'Search'),
                new ColumnData(JetColumnId.referrerSocial, 'Social'),
                new ColumnData(JetColumnId.referrerMisc, 'Misc'),
                new ColumnData(JetColumnId.pisNoReferrer, 'Kein Referrer'),
                new ColumnData(JetColumnId.readThroughRate, 'Read-Through').
                    setRelatedColumns([
                        JetColumnId.isPremium,
                        JetColumnId.articleType
                    ])
                    .setRTR(),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('bild'))
            ],
            viewModes: [ViewMode.BAR_CHART, ViewMode.TABLE]
        }),
        homeWeb: new PageData({
            label: PAGE_LABELS.homeWeb,
            sums: [
                new SumData(JetColumnId.homeReferralWeb, SUM_LABELS.home)
            ],
            columns: [
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.isVideo,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsType,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker,
                        JetColumnId.articleType
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.teaserPosition, 'Position', ColumnType.TEXT, true, JetOrderOption.ASC),
                new ColumnData(JetColumnId.department, 'Ressort', ColumnType.TEXT, false)
                    .setHighlight()
                    .setPostProcessor(homeDepartmentPostProcessor(BRANDS.BILD)),
                new ColumnData(JetColumnId.homeReferralWeb, 'Ref. Home', ColumnType.NUMERIC),
                new ColumnData(null, 'Split nach Plattform', ColumnType.TEXT, false)
                    .setBarChart({
                        barOptions: [
                            new BarOption(JetColumnId.homeReferralMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.homeReferralDesktop, 'Desktop', ColorMapping.DESKTOP)
                        ]
                    }),
                new ColumnData(JetColumnId.pisTotal, 'PIs', ColumnType.NUMERIC)
                    .setSparkline(),
                new ColumnData(JetColumnId.conversion, 'Conversions', ColumnType.NUMERIC)
                    .setRelatedColumns([JetColumnId.isPremium, JetColumnId.wasPremium])
                    .setPostProcessor(conversionProcessor()),
                new ColumnData(JetColumnId.readThroughRate, 'Read-Through').
                    setRelatedColumns([
                        JetColumnId.isPremium,
                        JetColumnId.articleType
                    ])
                    .setRTR(),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('bild'))
            ]
        }),
        premium: new PageData({
            label: PAGE_LABELS.premiumBild,
            sums: [
                new SumData(JetColumnId.conversion, <>{SUM_LABELS.premium}</>)
                    .setTacho({ style: { showTwoPerLine: true } }),
                new SumData(null, '', { sumContainer: { flexBasis: '100%', marginTop: -20, alignItems: 'center' } })
                    .setBarChart({
                        label: 'Conversions nach Referrer', style: { width: 300 }, barOptions: [
                            new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                        ]
                    })
                    .setSparkline({
                        sparklines: [
                            new SumDataSparkline(JetColumnId.conversionMobile, { label: 'Mobil', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionDesktop, { label: 'Desktop', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionApp, { label: 'App', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } })
                        ]
                    })
                    .setTimeline({ timeFilter: [TIME_LABEL.today] })
            ],
            columns: [
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url, JetColumnId.cmsType,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker,
                        JetColumnId.articleType
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.conversion, 'Conversions')
                    .setHighlight(),
                new ColumnData(null, 'Conversions nach Referrer', ColumnType.TEXT, false)
                    .setBarChart({
                        barOptions: [
                            new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                        ]
                    }),
                new ColumnData(JetColumnId.pisConversionPage, 'PIs Conversion Page'),
                new ColumnData(JetColumnId.pisPremium, 'PIs Premium Page'),
                new ColumnData(JetColumnId.pisTotal, 'PIs (Gesamt)'),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('bild'))
            ],
            filter: {
                conversion: 1
            }
        }),
        premiumSportBild: new PageData({
            label: PAGE_LABELS.premiumSportBild,
            sums: [
                new SumData(JetColumnId.conversion, <>{SUM_LABELS.premium}</>)
                    .setTacho({ style: { showTwoPerLine: true }, subTenant: 'sportbild' }),
                new SumData(null, '', { sumContainer: { flexBasis: '100%', marginTop: -20, alignItems: 'center' } })
                    .setBarChart({
                        label: 'Conversions nach Referrer', style: { width: 300 }, barOptions: [
                            new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                        ]
                    })
                    .setSparkline({
                        sparklines: [
                            new SumDataSparkline(JetColumnId.conversionMobile, { label: 'Mobil', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionDesktop, { label: 'Desktop', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionApp, { label: 'App', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } })
                        ]
                    })
                    .setTimeline({ timeFilter: [TIME_LABEL.today] })
            ],
            columns: [
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url, JetColumnId.cmsType,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsKicker, JetColumnId.articleKicker,
                        JetColumnId.articleType
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.conversion, 'Conversions')
                    .setHighlight(),
                new ColumnData(null, 'Conversions nach Referrer', ColumnType.TEXT, false)
                    .setBarChart({
                        barOptions: [
                            new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                        ]
                    }),
                new ColumnData(JetColumnId.pisConversionPage, 'PIs Conversion Page'),
                new ColumnData(JetColumnId.pisPremium, 'PIs Premium Page'),
                new ColumnData(JetColumnId.pisTotal, 'PIs (Gesamt)'),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('bild'))
            ],
            filter: {
                conversion: 1,
                departments: {
                    sportbild: 1
                }
            }
        }),
        video: SHARED_PAGE_CONFIG.video('bild'),
        seo: SHARED_PAGE_CONFIG.seo('bild'),
        watchlist: SHARED_PAGE_CONFIG.watchlist('bild'),
        positionAnalysis: new PageData({
            label: PAGE_LABELS.positionAnalysis
        })
    },
    welt: {
        topArticle: new PageData({
            label: PAGE_LABELS.topArticle,
            sums: [
                new SumData(JetColumnId.pisTotal, SUM_LABELS.topArticle)
                    .setBarChart({
                        label: 'PIs nach Referrer', style: { width: 250 }, barOptions: [
                            new BarOption(JetColumnId.pisMobile, 'Mobil'),
                            new BarOption(JetColumnId.pisDesktop, 'Desktop'),
                            new BarOption(JetColumnId.pisApps, 'App'),
                            new BarOption(JetColumnId.referrerSearch, 'Search'),
                            new BarOption(JetColumnId.referrerSocial, 'Social'),
                            new BarOption(JetColumnId.referrerMisc, 'Misc'),
                            new BarOption(JetColumnId.pisNoReferrer, 'Kein Referrer')
                        ]
                    }),
                new SumData(null)
                    .setSparkline({
                        style: { height: 200 }, timeFilter: [TIME_LABEL.today], sparklines: [
                            new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, { label: 'Gestern', chartOptions: { stack: 'PIs Gestern', areaStyle: { opacity: 0.2 } } })
                                .setRequestOptions({ sparklineTime: 'yesterday' }),
                            new SumDataSparkline(JetColumnId.pisMobile, { label: 'Mobil', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisDesktop, { label: 'Desktop', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisApps, { label: 'App', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisNoReferrer, { label: 'Kein Referrer', chartOptions: { stack: 'PIs' } })
                        ]
                    })
            ],
            columns: [
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsType,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker,
                        JetColumnId.articleType
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.pisTotal, 'PIs')
                    .setHighlight()
                    .setSparkline({ sparklineSharedMax: true, style: { width: 'min(max(20vw, 220px), 500px)' } }),
                new ColumnData(JetColumnId.pisMobile, 'Mobil'),
                new ColumnData(JetColumnId.pisDesktop, 'Desktop'),
                new ColumnData(JetColumnId.pisApps, 'App'),
                new ColumnData(JetColumnId.referrerSearch, 'Search'),
                new ColumnData(JetColumnId.referrerSocial, 'Social'),
                new ColumnData(JetColumnId.referrerMisc, 'Misc'),
                new ColumnData(JetColumnId.pisNoReferrer, 'Kein Referrer'),
                new ColumnData(JetColumnId.readThroughRate, 'Read-Through').
                    setRelatedColumns([
                        JetColumnId.isPremium,
                        JetColumnId.articleType
                    ])
                    .setRTR(),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('welt'))
            ],
            viewModes: [ViewMode.TABLE, ViewMode.BAR_CHART]
        }),
        home: new PageData({
            label: PAGE_LABELS.home,
            sums: [
                new SumData(JetColumnId.homeReferralWeb, SUM_LABELS.home)
            ],
            columns: [
                new ColumnData(JetColumnId.teaserPosition, 'Position', ColumnType.TEXT, true, JetOrderOption.ASC),
                new ColumnData(JetColumnId.department, 'Ressort', ColumnType.TEXT, false)
                    .setHighlight()
                    .setPostProcessor(homeDepartmentPostProcessor(BRANDS.WELT)),
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsType,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.homeReferralWeb, 'Ref. Home', ColumnType.NUMERIC),
                new ColumnData(JetColumnId.pisTotal, 'PIs', ColumnType.NUMERIC)
                    .setSparkline({ style: { height: 100, width: 460 }, sparklineSharedMax: true }),
                new ColumnData(JetColumnId.conversion, 'Conversions', ColumnType.NUMERIC)
                    .setRelatedColumns([JetColumnId.isPremium, JetColumnId.wasPremium])
                    .setPostProcessor(conversionProcessor()),
                new ColumnData(JetColumnId.readThroughRate, 'Read-Through').
                    setRelatedColumns([
                        JetColumnId.isPremium,
                        JetColumnId.articleType
                    ])
                    .setRTR(),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('welt'))
            ]
        }),
        premium: new PageData({
            label: PAGE_LABELS.premium,
            sums: [
                new SumData(JetColumnId.conversion, SUM_LABELS.premium, {
                    sumContainer: { width: '100%', alignItems: 'center' }
                })
                    .setTacho()
                    .setBarChart({
                        label: 'Conversions nach Referrer', style: { width: 250 }, barOptions: [
                            new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                        ]
                    })
                    .setSparkline({
                        sparklines: [
                            new SumDataSparkline(JetColumnId.conversionMobile, { label: 'Mobil', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionDesktop, { label: 'Desktop', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionApp, { label: 'App', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.conversionMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } })
                        ]
                    })
                    .setTimeline({ timeFilter: [TIME_LABEL.today] })
            ],
            columns: [
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.conversion, 'Conversions', ColumnType.TEXT)
                    .setTimelineBarChart({
                        style: { width: 300 },
                        sumSparklines: [
                            new SumDataSparkline(JetColumnId.conversionMobile, { label: 'Mobil' }),
                            new SumDataSparkline(JetColumnId.conversionDesktop, { label: 'Desktop' }),
                            new SumDataSparkline(JetColumnId.conversionApp, { label: 'App' }),
                            new SumDataSparkline(JetColumnId.conversionSearch, { label: 'Search' }),
                            new SumDataSparkline(JetColumnId.conversionSocial, { label: 'Social' }),
                            new SumDataSparkline(JetColumnId.conversionMisc, { label: 'Misc' })
                        ]
                    }),
                new ColumnData(JetColumnId.pisTotal, 'PIs (Gesamt)')
                    .setSparkline({ sparklineSharedMax: true, style: { width: 225 } }),
                new ColumnData(null, 'Conversions nach Referrer', ColumnType.TEXT, false)
                    .setBarChart({
                        barOptions: [
                            new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                        ]
                    }),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('welt'))
            ],
            filter: {
                conversion: 1
            }
        }),
        video: SHARED_PAGE_CONFIG.video('welt'),
        push: new PageData({
            label: PAGE_LABELS.push,
            sums: [
                new SumData(JetColumnId.pushNotification, 'PIs Push')
                    .setSparkline({ sparklinesPerRow: 2 }),
                new SumData(JetColumnId.pisTotal, 'PIs')
                    .setSparkline({ sparklinesPerRow: 2 })
            ],
            columns: [
                new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false)
                    .setRelatedColumns([
                        JetColumnId.url,
                        JetColumnId.isPremium,
                        JetColumnId.wasPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.cmsType,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker
                    ])
                    .setPostProcessor(titlePostProcessor()),
                new ColumnData(JetColumnId.pushNotification, 'Push PIs')
                    .setSparkline({ style: { width: 400 }, sparklineSharedMax: true }),
                new ColumnData(JetColumnId.id, '', ColumnType.ROW_ACTION)
                    .setPostProcessor(watchlistTogglePostProcessor('welt'))
            ]
        }),
        seo: SHARED_PAGE_CONFIG.seo('welt'),
        watchlist: new PageData({
            label: PAGE_LABELS.watchlist,
            viewModes: [ViewMode.WATCHLIST],
            watchlistKey: 'welt',
            filter: {
                topTeaserPositionFilter: true
            },
            columns: [
                new ColumnData(JetColumnId.id, 'ID')
                    .setRelatedColumns([
                        JetColumnId.url,
                        JetColumnId.id,
                        JetColumnId.pisTotal,
                        JetColumnId.conversion,
                        JetColumnId.pisPremium,
                        JetColumnId.isPremium,
                        JetColumnId.cmsIsPremium,
                        JetColumnId.pisConversionPage,
                        JetColumnId.department,
                        JetColumnId.title,
                        JetColumnId.teaserPosition,
                        JetColumnId.cmsKicker,
                        JetColumnId.articleKicker,
                        JetColumnId.cmsTeaserImage,
                        JetColumnId.cmsPublicationDate,
                        JetColumnId.readThroughRate,
                        JetColumnId.articleType
                    ]),
                new ColumnData(null, 'PIs nach Referrer')
                    .setBarChart({
                        barOptions: [
                            new BarOption(JetColumnId.pisMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.pisDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.pisApps, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.referrerSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.referrerSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.referrerMisc, 'Misc', ColorMapping.MISC),
                            new BarOption(JetColumnId.pisNoReferrer, 'Kein Referrer', ColorMapping['KEIN REFERRER'])
                        ],
                        style: { width: '100%' }
                    }),
                new ColumnData(null, 'Conversions nach Referrer')
                    .setBarChart({
                        barOptions: [
                            new BarOption(JetColumnId.conversionMobile, 'Mobil', ColorMapping.MOBIL),
                            new BarOption(JetColumnId.conversionDesktop, 'Desktop', ColorMapping.DESKTOP),
                            new BarOption(JetColumnId.conversionApp, 'App', ColorMapping.APP),
                            new BarOption(JetColumnId.conversionSearch, 'Search', ColorMapping.SEARCH),
                            new BarOption(JetColumnId.conversionSocial, 'Social', ColorMapping.SOCIAL),
                            new BarOption(JetColumnId.conversionMisc, 'Misc', ColorMapping.MISC)
                        ],
                        style: { width: '100%' }
                    }),
                new ColumnData(null, 'PIs')
                    .setSparkline({
                        style: {
                            'width': 'auto',
                            'height': 200
                        },
                        sumSparklines: [
                            new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, {
                                label: 'Gestern',
                                requestValue: true,
                                timeFilter: [TIME_LABEL.today],
                                chartOptions: { stack: 'PIs Gestern', areaStyle: { opacity: 0.2 } }
                            })
                                .setRequestOptions({ sparklineTime: 'yesterday' }),
                            new SumDataSparkline(JetColumnId.pisMobile, { label: 'Mobil', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisDesktop, { label: 'Desktop', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisApps, { label: 'App', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisNoReferrer, { label: 'Kein Referrer', chartOptions: { stack: 'PIs' } })
                        ]
                    }),
                new ColumnData(null, 'Conversions By Referrer and Time')
                    .setSparkline({
                        sumSparklines: [
                            new SumDataSparkline(JetColumnId.conversionMobile, { label: 'Mobil' }),
                            new SumDataSparkline(JetColumnId.conversionDesktop, { label: 'Desktop' }),
                            new SumDataSparkline(JetColumnId.conversionApp, { label: 'App' }),
                            new SumDataSparkline(JetColumnId.conversionSearch, { label: 'Search' }),
                            new SumDataSparkline(JetColumnId.conversionSocial, { label: 'Social' }),
                            new SumDataSparkline(JetColumnId.conversionMisc, { label: 'Misc' })
                        ]
                    })
            ]
        })
    },
    travelbook: {
        topArticle: SHARED_PAGE_CONFIG.booksTopArticle('travelbook'),
        seo: SHARED_PAGE_CONFIG.seo('travelbook'),
        watchlist: SHARED_PAGE_CONFIG.watchlist('travelbook')
    },
    techbook: {
        topArticle: SHARED_PAGE_CONFIG.booksTopArticle('techbook'),
        seo: SHARED_PAGE_CONFIG.seo('techbook'),
        watchlist: SHARED_PAGE_CONFIG.watchlist('techbook')
    },
    fitbook: {
        topArticle: SHARED_PAGE_CONFIG.booksTopArticle('fitbook'),
        seo: SHARED_PAGE_CONFIG.seo('fitbook'),
        watchlist: SHARED_PAGE_CONFIG.watchlist('fitbook')
    },
    stylebook: {
        topArticle: SHARED_PAGE_CONFIG.booksTopArticle('stylebook'),
        seo: SHARED_PAGE_CONFIG.seo('stylebook'),
        watchlist: SHARED_PAGE_CONFIG.watchlist('stylebook')
    },
    homebook: {
        topArticle: SHARED_PAGE_CONFIG.booksTopArticle('homebook'),
        seo: SHARED_PAGE_CONFIG.seo('homebook'),
        watchlist: SHARED_PAGE_CONFIG.watchlist('homebook')
    }
};


export { PAGE_CONFIGS };
