import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { WatchlistToggle } from '../Watchlist/WatchlistToggle';
import { TitleLink } from '../JetTable/TitleLink';
import { Article } from '../../../common/helpers/Utils';
import { DEPARTMENTS } from '../../helpers/constants/departments';

const convertAmpToWeltUrl = (url) => {
    const [match, path] = url.match(/amp\.welt.de\/(.*)\?amp_js_v/) || [''];
    if (match.startsWith('amp.welt.de')) {
        return 'https://welt.de/' + path;
    }
    return url;
};

export const titlePostProcessor = ({
    premiumIcon = true, videoIcon = true, liveTickerIcon = true,
    showKicker = true
} = {}) => (title, relatedData) => {
    const article = new Article(relatedData);
    let { url } = relatedData;
    let result = title;

    if (liveTickerIcon) {
        if (['sportdaten', 'live-sport'].includes(relatedData.articleType)) {
            result = <>{result}&nbsp;<b>(Ticker)</b></>;
        }
    }

    if (premiumIcon) {
        if (article.isPremium()) {
            result = <>{result}&nbsp;✚</>;
        } else if (article.wasPremium()) {
            result = <>{result}&nbsp;(✚)</>;
        }
    }

    if (videoIcon) {
        result = (<>{result}{article.isVideo() && <>&nbsp;▶</>}</>);
    }

    if (url) {
        url = convertAmpToWeltUrl(url);
        result = <TitleLink url={url}>{result}</TitleLink>;
    }

    // add other styles/wrappers for the title before tooltip, not after
    if (showKicker) {
        const { cmsKicker, articleKicker } = relatedData;
        const kicker = cmsKicker ? cmsKicker : (articleKicker || '');
        const tooltipText = kicker ? `${kicker}: ${title}` : title;
        result = (
            <Tooltip title={tooltipText} arrow>
                <div>
                    {result}
                </div>
            </Tooltip>
        );
    }

    return result;
};

// video url

const getVideoUrlForBrand = (brand, relatedData) => {
    if (brand === 'bild') {
        return `https://bild.de/cmsid/${relatedData.documentId}`;
    }

    if (brand === 'welt') {
        return `https://welt.de/${relatedData.mediaId}`;
    }

    throw new Error('Unhandled brand');
};

export const modifyVideoUrl = (brand, postProcessor) => (content, relatedData) => {
    return postProcessor(content, { ...relatedData, url: getVideoUrlForBrand(brand, relatedData) });
};

// others

export const conversionProcessor = (premiumFn = content => content) => (content, relatedData = {}) => {
    const article = new Article(relatedData);
    return article.wasPremium() ? premiumFn(content, relatedData) : '';
};

export const capPercentageProcessor = (content) => {
    return `${content > 100 ? '100' : content} %`;
};

/**
 * Format seconds into hh:mm:ss with optional hh part
 * Returns 00:00 if content type is not a number
 * @param {*} content duration in seconds as number
 * @returns duration format string
 */
export const watchTimeFormat = (content) => {
    if (typeof content === 'number') {
        let seconds = parseInt(content, 10);
        seconds = seconds < 0 ? 0 : seconds;
        let minutes = parseInt(seconds / 60, 10);
        let hours = parseInt(minutes / 60, 10);

        seconds -= minutes * 60;
        minutes -= hours * 60;

        seconds = seconds < 10 ? '0' + seconds : seconds;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        hours = hours < 10 ? '0' + hours : hours;
        return (hours === '00' ? '' : hours + ':') + `${minutes}:${seconds}`;
    } else {
        return '00:00';
    }
};

export const removeDecimalsProcessor = (content) => {
    if (typeof content === 'number') {
        const number = parseInt(content, 10);
        return capPercentageProcessor(number);
    }
    return content;
};

export const homeDepartmentPostProcessor = (brand) => (department) => {
    const departments = DEPARTMENTS[brand];
    return departments[department] || department;
};

export const conversionSearchWithConversionPostProcessor = (content, relatedData) => {
    return `${content} / ${relatedData.conversion}`;
};

export const convertToConversionPercent = (content, relatedData) => {
    if (!relatedData) {
        return 0;
    }
    return relatedData.conversion === 0 ? 0 : Math.round((100 * content) / relatedData.conversion);
};

/* eslint-disable react/display-name */
export const watchlistTogglePostProcessor = (watchlistKey) => (content) => {
    return <WatchlistToggle watchlistKey={watchlistKey} id={content} />;
};
